body {
  color: #00ee93;
  text-align: center;
  background-color: #000;
  margin: 0;
  font-family: monospace;
  font-size: 16px;
  font-weight: lighter;
}

input {
  color: #00ee93;
  background-color: #000;
  border: 1px solid #00ee93;
  border-radius: 4px;
  outline: none;
  padding: 10px;
}

input.error {
  color: red;
  border-color: red;
}

input::placeholder {
  color: #00ee9380;
}

button {
  cursor: pointer;
  background-color: #00ee93cc;
  border: none;
  border-radius: 4px;
  padding: .3em .5em;
  font-family: monospace;
  font-weight: lighter;
  transition: opacity .15s;
}

button:hover {
  opacity: .8;
}

button:active {
  opacity: .6;
}

main {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MDAiIGhlaWdodD0iNTAwIiB2aWV3Qm94PSIwIDAgMTMyLjI5MiAxMzIuMjkyIiB4bWxuczp2PSJodHRwczovL3ZlY3RhLmlvL25hbm8iPjxnIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDE2NC40MTk2NSAtNjAuMzg2ODkpIj48cGF0aCBkPSJNMzIuMTI4IDYwLjM4N0gxNjQuNDJWMTkyLjY4SDMyLjEyOHoiIHBhaW50LW9yZGVyPSJtYXJrZXJzIGZpbGwgc3Ryb2tlIi8+PHJlY3Qgcnk9Ii4wMjEiIHk9IjE5MS4zNTYiIHg9IjE2My4wOTciIGhlaWdodD0iMS4zMjMiIHdpZHRoPSIxLjMyMyIgZmlsbD0iIzBjMCIgcGFpbnQtb3JkZXI9Im1hcmtlcnMgZmlsbCBzdHJva2UiLz48L2c+PC9zdmc+");
  background-position: 50%;
  background-repeat: repeat;
  background-size: 5em;
  place-content: center;
  min-height: calc(100vh - 4em);
  padding: 2em;
  transition: opacity .3s 50ms;
  display: grid;
}

main p, main h1 {
  margin: 0;
}

main .selection {
  align-items: center;
  margin-top: 10vh;
}

main .selection .pools {
  flex-direction: column;
  align-items: center;
  margin: 1em;
  display: flex;
}

main .selection label {
  text-align: left;
  width: 130px;
}

main .principal {
  opacity: 0;
  -webkit-user-select: none;
  user-select: none;
}

main .principal.loaded {
  opacity: 1;
  -webkit-user-select: auto;
  user-select: auto;
  transition: opacity .5s;
}

main .principal > div {
  margin: 2em 0;
}

main #date, main #jourrestant {
  display: none;
}

main #date.shown, main #jourrestant.shown {
  display: block;
}

main #minerLink {
  color: #000;
  background: #00ee93cc;
  border-radius: 4px;
  padding: .3em .5em;
  text-decoration: none;
  transition: opacity .15s;
}

main #minerLink:hover {
  opacity: .8;
}

main #minerLink:active {
  opacity: .6;
}

main #history {
  opacity: 0;
  -webkit-user-select: none;
  user-select: none;
  transition: opacity .3s;
  display: none;
}

main #history.hasHistory {
  opacity: 1;
  user-select: initial;
}

main #history #liste {
  text-align: left;
  max-height: 200px;
  padding: 0 40px;
  overflow-y: auto;
}

main #history #liste p {
  margin-top: 1em;
}

::-webkit-scrollbar {
  background-color: #0000;
  width: 1px;
}

::-webkit-scrollbar-thumb {
  background-color: #00ee9380;
}
/*# sourceMappingURL=index.29154eec.css.map */
