$accent: #00ee93;

body {
	color: $accent;
	background-color: black;
	margin: 0;
	font-size: 16px;
	font-family: monospace;
	font-weight: lighter;
	text-align: center;
}

input {
	background-color: #000;
	border: 1px solid $accent;
	color: $accent;
	padding: 10px;
	border-radius: 4px;
	outline: none;

	&.error {
		border-color: red;
		color: red;
	}

	&::placeholder {
		color: rgba($accent, 0.5);
	}
}

button {
	background-color: transparentize($color: $accent, $amount: 0.2);
	font-family: monospace;
	font-weight: lighter;
	border-radius: 4px;
	padding: 0.3em 0.5em;
	border: none;
	cursor: pointer;
	transition: opacity 0.15s;

	&:hover {
		opacity: 0.8;
	}

	&:active {
		opacity: 0.6;
	}
}

main {
	display: grid;
	place-content: center;
	min-height: calc(100vh - 4em);
	padding: 2em;
	background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MDAiIGhlaWdodD0iNTAwIiB2aWV3Qm94PSIwIDAgMTMyLjI5MiAxMzIuMjkyIiB4bWxuczp2PSJodHRwczovL3ZlY3RhLmlvL25hbm8iPjxnIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDE2NC40MTk2NSAtNjAuMzg2ODkpIj48cGF0aCBkPSJNMzIuMTI4IDYwLjM4N0gxNjQuNDJWMTkyLjY4SDMyLjEyOHoiIHBhaW50LW9yZGVyPSJtYXJrZXJzIGZpbGwgc3Ryb2tlIi8+PHJlY3Qgcnk9Ii4wMjEiIHk9IjE5MS4zNTYiIHg9IjE2My4wOTciIGhlaWdodD0iMS4zMjMiIHdpZHRoPSIxLjMyMyIgZmlsbD0iIzBjMCIgcGFpbnQtb3JkZXI9Im1hcmtlcnMgZmlsbCBzdHJva2UiLz48L2c+PC9zdmc+');
	background-size: 5em;
	background-repeat: repeat;
	background-position: 50% 50%;
	transition: opacity 0.3s 0.05s;

	p,
	h1 {
		margin: 0;
	}

	.selection {
		align-items: center;
		margin-top: 10vh;

		.pools {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 1em;
		}

		label {
			text-align: left;
			width: 130px;
		}
	}

	.principal {
		opacity: 0;
		user-select: none;

		&.loaded {
			opacity: 1;
			user-select: auto;
			transition: opacity 0.5s;
		}

		> div {
			margin: 2em 0;
		}
	}

	#date,
	#jourrestant {
		display: none;
		&.shown {
			display: block;
		}
	}

	#minerLink {
		background: transparentize($color: $accent, $amount: 0.2);
		color: black;
		text-decoration: none;
		padding: 0.3em 0.5em;
		border-radius: 4px;
		transition: opacity 0.15s;

		&:hover {
			opacity: 0.8;
		}

		&:active {
			opacity: 0.6;
		}
	}

	#history {
		display: none;
		opacity: 0;
		user-select: none;
		transition: opacity 0.3s;

		&.hasHistory {
			opacity: 1;
			user-select: initial;
		}

		#liste {
			overflow-y: auto;
			text-align: left;
			max-height: 200px;
			padding: 0 40px;

			p {
				margin-top: 1em;
			}
		}
	}
}

::-webkit-scrollbar {
	width: 1px;
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: transparentize($color: $accent, $amount: 0.5);
}
